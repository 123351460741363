<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Form from "@/domain/roles/components/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewRole',
  components: {Form},
  data() {
    return {
      empty: {
        name: '',
        permissions: {},
        visible_by: [],
        description: '',
      }
    }
  },
  methods: {
    ...mapActions('roles', [
      'createRole',
    ]),
    async onSave(data) {
      await this.createRole(data)
      await this.$router.push({name: 'roles.list'})
      throw new SuccessNotification(this.$pgettext('roles', 'Role created!'))
    }
  }
}
</script>

<style scoped>

</style>